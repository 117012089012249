@tailwind components;

@layer components {
  .SelectPlaylist-smScreen-show {
    @apply z-50 absolute w-full opacity-100;
  }
  .SelectPlaylist-smScreen {
    @apply -z-50 absolute w-full opacity-0;
  }
  .SelectPlaylist {
    @apply w-0;
  }
  .SelectPlaylist-show {
    @apply w-48 lg:w-60;
  }
}

.showPlayerWhenSmScreen {
  animation: showPlayerWhenSmScreen 0.2s ease-in-out;
  transform: translateY(-110%);
}
@keyframes showPlayerWhenSmScreen {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-110%);
  }
}
.hidePlayerWhenSmScreen {
  animation: hidePlayerWhenSmScreen 0.2s ease-in-out;
  transform: translateY(0);
}
@keyframes hidePlayerWhenSmScreen {
  from {
    transform: translateY(-110%);
  }
  to {
    transform: translateY(0);
  }
}
