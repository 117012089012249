@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Noto Sans KR', sans-serif !important;
  word-break: keep-all;
}

:root {
  --primary-redrose: #d50909;
  --primary-yellow: #ffd169;
  --blackberry: #1d1d1d;
  --blackberry-light: #757575;
  --blackberry-lightest: #aeaeae;
  --background-light: #f9f9f9;
  --background-dark: #757575;
}

/* swiper 라이브러리의 기본 색상 변경*/
:root {
  --swiper-navigation-color: var(--blackberry);
  --swiper-pagination-color: var(--blackberry);
}

/* 스크롤바 스타일 변경. 크롬에서만 적용됨. */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--blackberry-lightest);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--blackberry-light);
}

/* autocomplete 시 배경화면 변하는 거 없애기 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

/* input:focus 시 생기는 outline 제거 */
input:focus {
  outline: none;
}

/* rc-slider 핸들 드래그 시 생기는 그림자 커스텀*/
.rc-slider-handle-dragging {
  box-shadow: 0 0 5px var(--primary-yellow) !important;
}
.rc-slider-handle:active {
  box-shadow: 0 0 5px var(--primary-yellow) !important;
}
.rc-slider-dot-active {
  border-color: var(--blackberry) !important;
}

/* 스크롤바 없애고 싶을때 */
/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.noscrollbar::-webkit-scrollbar {
  display: none;
}

/** form 스타일 */
.form_field:placeholder-shown ~ .form__label {
  font-size: 1rem;
  top: 0;
  cursor: text;
}
.form_field:focus ~ .form__label {
  top: -1.2rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary-yellow);
}
.form_field:not(:focus) ~ .form__label {
  color: var(--blackberry-lightest);
}
.form_field:not(:placeholder-shown) ~ .form__label {
  top: -1.2rem;
  font-size: 1rem;
}

/* absolute로 부모 요소의 중앙에 오게하고 싶을 때 */
.absolute-place-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
