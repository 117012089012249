/* 앱 기본 설정 */
.app {
  background-color: var(--blackberry);
  color: var(--blackberry);
}

@tailwind components;
@layer components {
  .app-content-container {
    @apply h-full grid grid-cols-1 grid-rows-[4rem_minmax(0px,_auto)] overflow-auto bg-background-light pt-6;
  }
  .app-content-container-md {
    @apply flex-1 rounded-l-3xl;
  }
  .app-content-container-sm {
    @apply w-screen;
  }
}
